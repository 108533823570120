import React, { useState } from "react";
import Product from "../components/Product";
import { useCart } from "../contexts/CartContext";
import { IProductCategory } from "../interfaces/IProductCategory";
import { IProduct } from "../interfaces/IProduct";

const Shop: React.FC = () => 
{
    const { product, productCategory } = useCart();
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 15;

    const handleCategoryClick = (categoryId: number) => {
        setSelectedCategory(categoryId);
        setCurrentPage(1); // Reseta para a primeira página ao mudar de categoria
    };

    // Filtra os produtos com base na categoria selecionada
    const filteredProducts = selectedCategory
        ? product.filter((p: IProduct) => p.productCategory.id === selectedCategory)
        : product;

    // Calcula o número total de páginas
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    // Produtos exibidos na página atual
    const paginatedProducts = filteredProducts.slice(
        (currentPage - 1) * productsPerPage,
        currentPage * productsPerPage
    );

    // Funções de navegação
    const goToPage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    // Páginas a serem exibidas
    const getPageNumbers = () => {
        const maxPagesToShow = 5;
        const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    return (
        <React.Fragment>
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-3">
                        <h1 className="h2 pb-4">Categorias</h1>
                        <ul className="list-unstyled templatemo-accordion">
                            <li className="pb-3">
                                <ul className="collapse show list-unstyled pl-3">
                                    <li>
                                        <a
                                            className={`text-decoration-none ${selectedCategory === null ? "active" : ""}`}
                                            href="#!"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedCategory(null); // Limpa o filtro para mostrar todos os produtos
                                            }}
                                        >
                                            Todas as Categorias
                                        </a>
                                    </li>
                                    {productCategory?.map((x: IProductCategory) => (
                                        <li key={x.id}>
                                            <a
                                                className={`text-decoration-none ${selectedCategory === x.id ? "active" : ""}`}
                                                href="#!"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleCategoryClick(x.id);
                                                }}
                                            >
                                                {x.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-9">
                        <div className="row">
                            {paginatedProducts.map((product: IProduct) => (
                                <Product key={product.id} product={product} />
                            ))}
                        </div>

                        {/* Navegação da paginação */}
                        <div className="pagination d-flex justify-content-center align-items-center mt-4">
                            <button 
                                className="btn btn-outline-primary me-2" 
                                onClick={goToPreviousPage} 
                                disabled={currentPage === 1}
                            >
                                <i className="fas fa-arrow-left"></i>
                            </button>

                            {getPageNumbers().map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`btn ${pageNumber === currentPage ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                    onClick={() => goToPage(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}

                            <button 
                                className="btn btn-outline-primary ms-2" 
                                onClick={goToNextPage} 
                                disabled={currentPage === totalPages}
                            >
                                <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Shop;
