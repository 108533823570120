import React from "react";
import { IProduct } from "../interfaces/IProduct";
import { Link } from "react-router-dom";

interface ProductCardProps {
    product: IProduct;
}

const Product: React.FC<ProductCardProps> = ({ product }) => {

    const imgUrl = `https://oovaz-bucket.s3.sa-east-1.amazonaws.com/sampadescartaveis/products/${product.productCategory.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/${product.photo}`;

    return (
        <div className="col-md-4">
            <div className="card mb-4 product-wap rounded-0">
                <div className="card rounded-0">
                    <img className="card-img rounded-0" src={imgUrl} alt={product.name} height={"180px"} />
                    <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">
                        <ul className="list-unstyled">
                            <li><Link className="btn btn-danger text-white mt-2" to={`/produto/${product.id}`}><i className="far fa-eye"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <a href="shop-single.html" className="h3 text-decoration-none">{product.name}</a>
                    <p className="mb-3 mt-3">
                        {product.description.length > 100
                            ? `${product.description.slice(0, 100)}...`
                            : product.description}
                    </p>
                    {/* <div className="d-flex justify-content-between align-items-center mb-2">
                        <button className="btn text-red" onClick={handleAddToCart}>
                            <i className="fas fa-cart-plus"></i> Adicionar ao carrinho
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Product;
