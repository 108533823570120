import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from "./pages/About";
import Shop from "./pages/Shop";
import Contact from "./pages/Contact";
import Cart from "./pages/Cart";
import NotFound from "./pages/NotFound";
import ShopSingle from "./pages/ShopSingle";

import { CartProvider } from "./contexts/CartContext";

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <CartProvider>
            <Router future={{ 
                v7_relativeSplatPath: true,
                v7_startTransition: true
            }}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="/quem-somos" element={<About />} />
                        <Route path="/produtos" element={<Shop />} />
                        <Route path="/contatos" element={<Contact />} />
                        <Route path="/meu-carrinho" element={<Cart />} />
                        <Route path="/produto/:id" element={<ShopSingle />} />

                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Router>
        </CartProvider>
    </React.StrictMode>
);

reportWebVitals();
