import React from "react";
import MapComponent from "../components/MapComponent";

const Contact: React.FC = () => 
{
    const address = {
        center: { lat: -23.547786738431462, lng: -47.43622708699773 },
        zoom: 13,
        popupText: "Av. Santo Antônio, 150 - Barra Funda, Votorantim - SP, 18114-345",
        apiKey: "AIzaSyAxiIo6Sg-eZiV2uKJIlsLTFugC-d7j4W0"
    };

    return (
        <React.Fragment>
            <div className="container-fluid bg-light py-5">
                <div className="col-md-6 m-auto text-center">
                    <h1 className="h1">Contact Us</h1>
                    <p>
                        Proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        Lorem ipsum dolor sit amet.
                    </p>
                </div>
            </div>

            <MapComponent 
                center={address.center} 
                zoom={address.zoom} 
                popupText={address.popupText} 
                apiKey={address.apiKey}
            />

            <div className="container py-5">
                <div className="row py-5">
                    <form className="col-md-9 m-auto" method="post" role="form">
                        <div className="row">
                            <div className="form-group col-md-6 mb-3">
                                <label>Name</label>
                                <input type="text" className="form-control mt-1" id="name" name="name" placeholder="Name" />
                            </div>
                            <div className="form-group col-md-6 mb-3">
                                <label>Email</label>
                                <input type="email" className="form-control mt-1" id="email" name="email" placeholder="Email" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Subject</label>
                            <input type="text" className="form-control mt-1" id="subject" name="subject" placeholder="Subject" />
                        </div>
                        <div className="mb-3">
                            <label>Message</label>
                            <textarea className="form-control mt-1" id="message" name="message" placeholder="Message" rows={8}></textarea>
                        </div>
                        <div className="row">
                            <div className="col text-end mt-2">
                                <button type="submit" className="btn btn-success btn-lg px-3">Let’s Talk</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Contact;
