import React from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

interface MapComponentProps {
  center: { lat: number; lng: number }; // latitude e longitude
  zoom: number;
  popupText?: string;
  apiKey: string; // Chave da API do Google Maps
}

const MapComponent: React.FC<MapComponentProps> = ({ center, zoom, popupText, apiKey }) => {
  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '300px' }}
        center={center}
        zoom={zoom}
      >
        <Marker position={center} />
        {popupText && (
          <InfoWindow position={center}>
            <div>
              <h4>{popupText}</h4>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
