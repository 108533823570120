import React, { useState, useRef, useEffect } from 'react';
import { useCart } from '../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiClient from '../configs/ApiClient';

const Cart: React.FC = () => {
    const { state, dispatch } = useCart();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        telefone: '',
        nomeRazaoSocial: '',
        cpfCnpj: ''
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    let modalInstance: any;

    useEffect(() => {
        if (modalRef.current) {
            modalInstance = new (window as any).bootstrap.Modal(modalRef.current);
        }
    }, []);

    const updateQuantity = (productId: number, quantity: number) => {
        dispatch({
            type: 'UPDATE_QUANTITY',
            payload: { productId, quantity },
        });
    };

    const removeFromCart = (productId: number) => {
        dispatch({
            type: 'REMOVE_FROM_CART',
            payload: { productId },
        });
    };

    const handleQuantityChange = (productId: number, value: string) => {
        const quantity = parseInt(value, 10);
        if (!isNaN(quantity) && quantity >= 0) {
            updateQuantity(productId, quantity);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFinalizarPedido = async () => {
        if (validateForm()) {
            setLoading(true);
            setStatusMessage(null);

            const orderData = {
                telephone: formData.telefone,
                clients: {
                    email: formData.email,
                    Name_CorporateReason: formData.nomeRazaoSocial,
                    CPF_CNPJ: formData.cpfCnpj
                },
                orderProducts: state.items.map(item => ({
                    productId: item.product.id,
                    Amount: item.quantity
                }))
            };

            try {
                const response = await apiClient.post('/api/order', orderData);

                if (response.status === 200) {
                    setStatusMessage("Pedido finalizado com sucesso! Redirecionando para página inicial...");
                    setIsSuccess(true);
                    localStorage.clear();
                    setTimeout(() => window.location.href = "/", 3000);
                }
            } catch (error) {
                setStatusMessage("Não foi possível realizar o seu pedido. Por favor, tente novamente.");
                setIsSuccess(false);
            } finally {
                setLoading(false);
            }
        }
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!formData.email.trim()) {
            newErrors.email = 'O campo de e-mail é obrigatório.';
        }
        if (!formData.telefone.trim()) {
            newErrors.telefone = 'O campo de telefone é obrigatório.';
        }
        if (!formData.nomeRazaoSocial.trim()) {
            newErrors.nomeRazaoSocial = 'O campo de nome/razão social é obrigatório.';
        }
        if (!formData.cpfCnpj.trim()) {
            newErrors.cpfCnpj = 'O campo de CPF/CNPJ é obrigatório.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const formatTelefone = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .slice(0, 15);
    };

    const formatCpfCnpj = (value: string) => {
        const cleanedValue = value.replace(/\D/g, '');
        if (cleanedValue.length <= 11) {
            return cleanedValue
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        } else {
            return cleanedValue
                .replace(/^(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1/$2')
                .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
        }
    };

    const handleTelefoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatTelefone(e.target.value);
        setFormData(prevData => ({ ...prevData, telefone: formattedValue }));
    };

    const handleCpfCnpjChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatCpfCnpj(e.target.value);
        setFormData(prevData => ({ ...prevData, cpfCnpj: formattedValue }));
    };

    return (
        <React.Fragment>
            <section className="container py-5">
                <div className="row text-center pt-5 pb-3">
                    <div className="col-lg-6 m-auto">
                        <h1 className="h1">Meu Carrinho</h1>
                        <p>Confira seu carrinho de compras e garanta seus produtos favoritos antes que eles esgotem. Aproveite!</p>
                    </div>
                </div>
                <div className="row">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Nome do Produto</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.items.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>O carrinho está vazio.</td>
                                </tr>
                            ) : (
                                state.items.map((item) => (
                                    <tr key={item.product.id}>
                                        <td className="text-left">{item.product.name}</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                style={{ width: '70px' }}
                                                value={item.quantity || ""}
                                                onChange={(e) => handleQuantityChange(item.product.id, e.target.value)}
                                                min="1"
                                            />
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => removeFromCart(item.product.id)}
                                            >
                                                <i className="fas fa-trash-alt"></i> Remover
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="row mt-4">
                    <div className="col text-start">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => navigate('/produtos')}
                        >
                            <i className="fas fa-arrow-left"></i> Continuar Comprando
                        </button>
                    </div>
                    <div className="col text-end">
                        <button
                            className="btn btn-success"
                            data-bs-toggle="modal"
                            data-bs-target="#finalizarPedidoModal"
                        >
                            Finalizar Pedido <i className="fas fa-check"></i>
                        </button>
                    </div>
                </div>
            </section>

            <div
                className="modal fade"
                id="finalizarPedidoModal"
                tabIndex={-1}
                ref={modalRef}
                aria-labelledby="finalizarPedidoModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="finalizarPedidoModalLabel">Finalizar Pedido</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="Digite seu email"
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="telefone" className="form-label">Telefone</label>
                                    <input
                                        type="tel"
                                        className={`form-control ${errors.telefone ? 'is-invalid' : ''}`}
                                        id="telefone"
                                        name="telefone"
                                        value={formData.telefone}
                                        onChange={handleTelefoneChange}
                                        placeholder="Digite seu telefone"
                                    />
                                    {errors.telefone && <div className="invalid-feedback">{errors.telefone}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="nomeRazaoSocial" className="form-label">Nome / Razão Social</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.nomeRazaoSocial ? 'is-invalid' : ''}`}
                                        id="nomeRazaoSocial"
                                        name="nomeRazaoSocial"
                                        value={formData.nomeRazaoSocial}
                                        onChange={handleInputChange}
                                        placeholder="Digite seu nome ou razão social"
                                    />
                                    {errors.nomeRazaoSocial && <div className="invalid-feedback">{errors.nomeRazaoSocial}</div>}
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="cpfCnpj" className="form-label">CPF / CNPJ</label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.cpfCnpj ? 'is-invalid' : ''}`}
                                        id="cpfCnpj"
                                        name="cpfCnpj"
                                        value={formData.cpfCnpj}
                                        onChange={handleCpfCnpjChange}
                                        placeholder="Digite seu CPF ou CNPJ"
                                    />
                                    {errors.cpfCnpj && <div className="invalid-feedback">{errors.cpfCnpj}</div>}
                                </div>
                            </form>
                            {loading && <div className="spinner-border text-primary my-3" role="status"><span className="visually-hidden">Loading...</span></div>}
                            {statusMessage && (
                                <div className={`alert ${isSuccess ? "alert-success" : "alert-danger"} mt-3`}>
                                    {statusMessage}
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-success" onClick={handleFinalizarPedido}>Confirmar Pedido</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Cart;
