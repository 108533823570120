import React from "react";
import { ToastContainer } from 'react-toastify';
import { useCart } from "../contexts/CartContext";
import { IProductCategory } from "../interfaces/IProductCategory";
import { Link } from "react-router-dom";

const Footer: React.FC = () => 
{
    const { productCategory, configuration } = useCart();

    const socialLinks = [
        { name: 'facebook', url: configuration.facebook, icon: 'fab fa-facebook-f' },
        { name: 'instagram', url: configuration.instagram, icon: 'fab fa-instagram' },
        { name: 'twitter', url: configuration.twitter, icon: 'fab fa-twitter' },
        { name: 'linkedin', url: configuration.linkedin, icon: 'fab fa-linkedin' },
    ];

    return (
        <React.Fragment>
            <footer className="bg-black" id="tempaltemo_footer">
                <div className="container">
                    <div className="row">

                        <div className="col-md-4 pt-5">
                            <ul className="list-unstyled text-light footer-link-list">
                                <li><i className="fas fa-map-marker-alt fa-fw"></i> {configuration.address}</li>
                                <li><i className="fa fa-phone fa-fw"></i> {configuration.telephones}</li>
                                <li><i className="fa fa-envelope fa-fw"></i> {configuration.email}</li>
                            </ul>
                        </div>

                        <div className="col-md-4 pt-5">
                            <h2 className="h2 text-light border-bottom pb-3 border-light">Categorias</h2>
                            <ul className="list-unstyled text-light footer-link-list">
                            {productCategory?.map((x: IProductCategory) => (
                                <li key={x.id}><Link className="text-decoration-none" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); }} to="/produtos">{x.name}</Link></li>
                            ))}
                            </ul>
                        </div>

                        <div className="col-md-4 pt-5">
                            <h2 className="h2 text-light border-bottom pb-3 border-light">Empresa</h2>
                            <ul className="list-unstyled text-light footer-link-list">
                                <li><Link className="text-decoration-none" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); }} to="/">Página Inicial</Link></li>
                                <li><Link className="text-decoration-none" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' }); }} to="/quem-somos">Quem Somos</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row text-light mb-4">
                        <div className="col-12 mb-3">
                            <div className="w-100 my-3 border-top border-light"></div>
                        </div>
                        <div className="col-auto me-auto">
                            <ul className="list-inline text-left footer-icons">
                                {socialLinks.map((link) => (
                                    <li key={link.name} className="list-inline-item border border-light rounded-circle text-center">
                                        <a className="text-light text-decoration-none" target="_blank" rel="noopener noreferrer" href={link.url}>
                                            <i className={`${link.icon} fa-lg fa-fw`}></i>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="w-100 bg-black py-3">
                    <div className="container">
                        <div className="row pt-2">
                            <div className="col-12">
                                <p className="text-left text-light">Copyright &copy; 2024 Sampa Descartáveis</p>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <ToastContainer />
        </React.Fragment>
    );
};

export default Footer;
