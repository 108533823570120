import React, { useState } from "react";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { useCart } from "../contexts/CartContext";
import { IProduct } from "../interfaces/IProduct";

const ShopSingle: React.FC = () => {
    const { product, dispatch } = useCart();
    const { id } = useParams<{ id: string }>();
    const [quantity, setQuantity] = useState(1);

    const filteredProduct = product.find((p: IProduct) => p.id === Number(id));

    const imgUrl = filteredProduct
        ? `https://oovaz-bucket.s3.sa-east-1.amazonaws.com/sampadescartaveis/products/${filteredProduct.productCategory.name
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}/${filteredProduct.photo}`
        : "";

    const handleAddToCart = () => {
        if (filteredProduct) {
            dispatch({
                type: 'ADD_TO_CART',
                payload: { product: filteredProduct, quantity },
            });

            toast.success(`${filteredProduct.name} foi adicionado ao carrinho!`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(Number(event.target.value));
        setQuantity(value);
    };

    if (!filteredProduct) {
        return <p>Produto não encontrado.</p>;
    }

    return (
        <React.Fragment>
            <section>
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-lg-5 mt-5">
                            <div className="card mb-3">
                                <img className="card-img img-fluid" src={imgUrl} alt={filteredProduct.name} id="product-detail" />
                            </div>
                        </div>

                        <div className="col-lg-7 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <h1 className="h2">{filteredProduct.name}</h1>
                                    <p className="mt-3">{filteredProduct.description}</p>

                                    <div className="row align-items-center pb-3">
                                        <div className="col-md-4">
                                            <label htmlFor="quantity" className="form-label fw-bold">Quantidade:</label>
                                            <input
                                                type="number"
                                                id="quantity"
                                                min="1"
                                                value={quantity}
                                                onChange={handleQuantityChange}
                                                className="form-control text-center"
                                                style={{ maxWidth: '100px' }}
                                            />
                                        </div>
                                        <div className="col-md-8 mt-3 mt-md-0">
                                            <button
                                                type="button"
                                                className="btn btn-success btn-lg w-100"
                                                onClick={handleAddToCart}
                                            >
                                                <i className="fas fa-cart-plus me-2"></i> Adicionar ao Carrinho
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default ShopSingle;
