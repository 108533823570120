import React, { createContext, useContext, useReducer, useEffect, useState } from 'react';
import { ICartContextType } from '../interfaces/ICartContextType';
import { ICartState } from '../interfaces/ICartState';
import { ICartAction } from '../interfaces/ICartAction';
import { ICartProviderProps } from '../interfaces/ICartProviderProps';
import apiClient from '../configs/ApiClient';

const CartContext = createContext<ICartContextType | undefined>(undefined);

const cartReducer = (state: ICartState, action: ICartAction): ICartState => 
{
    switch (action.type) 
    {
        case 'ADD_TO_CART':
            if (!action.payload.product) return state;
            const existingItem = state.items.find(
                (item) => item.product.id === action.payload.product!.id
            );

            if (existingItem) {
                return {
                    ...state,
                    items: state.items.map((item) =>
                        item.product.id === action.payload.product!.id
                            ? { ...item, quantity: item.quantity + (action.payload.quantity || 1) }
                            : item
                    ),
                };
            }

            return {
                ...state,
                items: [
                    ...state.items,
                    { product: action.payload.product, quantity: action.payload.quantity || 1 }
                ],
            };

        case 'REMOVE_FROM_CART':
            if (action.payload.productId === undefined) return state;

            return {
                ...state,
                items: state.items.filter(
                    (item) => item.product.id !== (action.payload.productId as NonNullable<number>)
                ),
            };

        case 'UPDATE_QUANTITY':
            if (action.payload.productId === undefined || action.payload.quantity === undefined) {
                return state;
            }

            return {
                ...state,
                items: state.items.map((item) =>
                    item.product.id === (action.payload.productId as NonNullable<number>)
                        ? {
                              ...item,
                              quantity: Math.max(1, action.payload.quantity as NonNullable<number>),
                          }
                        : item
                ),
            };

        default:
            return state;
    }
};

export const CartProvider: React.FC<ICartProviderProps> = ({ children }) => 
{
    const [product, setProduct] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [configuration, setConfiguration] = useState([]);

    const [state, dispatch] = useReducer(cartReducer, { items: [] }, () => 
    {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : { items: [] };
    });

    const getCartItemCount = () => 
    {
        return state.items.length;
    };

    const fetchProduct = async () => {
        try {
            const response = await apiClient.get("/api/product");
            setProduct(response.data.products);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    } 

    const fetchProductCategories = async () => {
        try {
            const response = await apiClient.get("/api/productCategory");
            setProductCategory(response.data.productCategories);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    }

    const fetchConfigurations = async () => {
        try {
            const response = await apiClient.get("/api/configuration");
            console.log(response);
            setConfiguration(response.data);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    } 

    useEffect(() => {
        fetchProduct();
        fetchProductCategories();
        fetchConfigurations();
    }, []);

    useEffect(() => 
    {
        localStorage.setItem('cart', JSON.stringify(state));
    }, [state]);

    return (
        <CartContext.Provider value={{ state, dispatch, getCartItemCount, product, productCategory, configuration }}>
            {children}
        </CartContext.Provider>
    );

};

export const useCart = () =>
{
    const context = useContext(CartContext);
    if (!context) throw new Error('useCart must be used within a CartProvider');
    return context;
};
