import React, { useEffect, useState } from "react";
import apiClient from "../configs/ApiClient";
import { IAbout } from "../interfaces/IAbout";
import { useCart } from "../contexts/CartContext";

const About: React.FC = () => 
{
    const { configuration } = useCart();

    return (
        <React.Fragment>
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center py-5">
                        <div className="col-md-8 text-black">
                            <h1 className="mb-2">Quem Somos</h1>
                            <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{ __html: configuration?.about || "Carregando conteúdo..." }}
                            />
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src="assets/img/logo_vertical.png" alt="Sampa descartáveis" width="90%" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default About;
