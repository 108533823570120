import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
    return (
        <section className="container py-5">
            <div className="row text-center pt-5 pb-3">
                <div className="col-lg-6 m-auto">
                    <h1 className="h1">404 - Página Não Encontrada</h1>
                    <p>A página que você está tentando acessar não existe.</p>
                    <Link to="/">Voltar para a Página Inicial</Link>
                </div>
            </div>
        </section>
    );
};

export default NotFound;
