import React from "react";
import { useCart } from "../contexts/CartContext";

const TopNav: React.FC = () => 
{
    const { configuration } = useCart();

    return (    
        <nav className="navbar navbar-expand-lg bg-black navbar-light d-none d-lg-block" id="templatemo_nav_top">
            <div className="container text-light">
                <div className="w-100 d-flex justify-content-between">
                    <div>
                        <i className="fa fa-envelope mx-2"></i>
                        <a className="navbar-sm-brand text-light text-decoration-none" href="mailto:info@company.com">{configuration.email}</a>
                        <i className="fa fa-phone mx-2"></i>
                        <a className="navbar-sm-brand text-light text-decoration-none" href="tel:010-020-0340">{configuration.telephones}</a>
                    </div>
                    <div>
                        <a className="text-light" href={configuration.facebook} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f fa-sm fa-fw me-2"></i></a>
                        <a className="text-light" href={configuration.instagram} target="_blank" rel="noreferrer"><i className="fab fa-instagram fa-sm fa-fw me-2"></i></a>
                        <a className="text-light" href={configuration.twitter} target="_blank" rel="noreferrer"><i className="fab fa-twitter fa-sm fa-fw me-2"></i></a>
                        <a className="text-light" href={configuration.linkedin} target="_blank" rel="noreferrer"><i className="fab fa-linkedin fa-sm fa-fw"></i></a>
                    </div>
                </div>
            </div>
        </nav>
    )
};

export default TopNav;