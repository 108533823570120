import React from "react";
import { Link } from "react-router-dom";
import { useCart } from '../contexts/CartContext';

const Header: React.FC = () => 
{
    const { getCartItemCount } = useCart();

    return (
        <nav className="navbar navbar-expand-lg navbar-light shadow">
            <div className="container d-flex justify-content-between align-items-center">

                <Link className="navbar-brand text-success logo h1 align-self-center" to="/">
                    <img src="/assets/img/Logo.png" className="img-logo" alt="logo" />
                </Link>

                <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#templatemo_main_nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="align-self-center collapse navbar-collapse flex-fill d-lg-flex justify-content-lg-end" id="templatemo_main_nav">
                    <div className="navbar align-self-center d-flex ms-auto">
                        <ul className="nav navbar-nav d-flex justify-content-center">
                            <li className="nav-item">
                                <Link className="nav-link" to="/quem-somos">Quem Somos</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/produtos">Nossos Produtos</Link>
                            </li>
                        </ul>
                        <Link className="nav-icon position-relative text-decoration-none ms-3" to="/meu-carrinho">
                            <i className="fa fa-fw fa-cart-arrow-down text-dark mr-1"></i>
                            <span className="position-absolute top-0 left-100 translate-middle badge rounded-pill bg-light text-dark">{getCartItemCount()}</span>
                        </Link>
                    </div>
                </div>

            </div>
        </nav>
    );
};

export default Header;
