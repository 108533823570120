import React from "react";
import { useCart } from "../contexts/CartContext";
import { IProductCategory } from "../interfaces/IProductCategory";
import { Link } from "react-router-dom";
import { IProduct } from "../interfaces/IProduct";

const Home: React.FC = () => 
{
    const { product, productCategory } = useCart();

    const handleUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="home-container">
            <React.Fragment>
                <section className="container py-5">
                    <div className="row text-center pt-3">
                        <div className="col-lg-6 m-auto">
                            <h1 className="h1">Nossas Linhas</h1>
                            <p>Descubra o melhor para você com nossa linha completa de produtos de qualidade, feitos para transformar seu dia a dia!</p>
                        </div>
                    </div>
                    <div className="row">
                        {productCategory?.map((x: IProductCategory) => (                        
                            <div className="col-12 col-md-4 p-5 mt-3 d-flex flex-column align-items-center" key={x.id}>
                                <Link className="text-decoration-none" onClick={handleUp} to="/produtos">
                                    <img 
                                        src={`https://oovaz-bucket.s3.sa-east-1.amazonaws.com/sampadescartaveis/categories/${x.photo}`} 
                                        className="rounded-circle border img-fixed-size" 
                                        alt={x.name} 
                                    />
                                </Link>
                                <h5 className="text-center mt-3 mb-3">{x.name}</h5>
                                <p className="text-center"><Link className="btn btn-red" onClick={handleUp} to={'/produtos'}>ir para loja</Link></p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="bg-light">
                    <div className="container py-5">
                        <div className="row text-center py-3">
                            <div className="col-lg-6 m-auto">
                                <h1 className="h1">Produtos em destaque</h1>
                                <p>Conheça os Produtos em Destaque da nossa linha e surpreenda-se com qualidade e inovação pensadas para você!</p>
                            </div>
                        </div>
                        <div className="row">
                            {product
                            ?.filter((x: IProduct) => x.emphasis)                     
                            .map((x: IProduct) => (                        
                                <div className="col-12 col-md-4 mb-4" key={x.id}>
                                    <div className="card h-100">
                                        <a href="shop-single.html">
                                            <img 
                                                src={`https://oovaz-bucket.s3.sa-east-1.amazonaws.com/sampadescartaveis/products/${x.productCategory.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}/${x.photo}`} 
                                                className="card-img-top"
                                                height={'300px'}
                                                alt={x.name} 
                                            />
                                        </a>
                                        <div className="card-body">
                                            <a href="shop-single.html" className="h2 text-decoration-none text-dark mb-3">{x.name}</a>
                                            <p className="card-text mt-3">                                            
                                                {x.description.length > 200
                                                ? `${x.description.slice(0, 200)}...`
                                                : x.description}
                                            </p>
                                            <Link className="text-muted" to={`/produto/${x.id}`}>Ver produto</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        </div>
    );
};

export default Home;
