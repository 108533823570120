import { Outlet } from 'react-router-dom';

import React from "react";
import TopNav from "./TopNav";
import Header from "./Header";
import Modal from "./Modal";
import Footer from './Footer';

const Layout: React.FC = () => 
{
    
    return (    
        <React.Fragment>
            <TopNav />
            <Header />
            <Modal />
            <Outlet />
            <Footer />
        </React.Fragment>
    )
};

export default Layout;